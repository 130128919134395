<template>
  <div>
    <template v-if="group">
      <custom-breadcrumb
        :pageTitle="$t('Group')"
        :breadcrumb="breadcrumb"
      ></custom-breadcrumb>

      <b-row>
        <b-col
          cols="12"
          lg="6"
        >
          <info-card 
            :school="school"
            :group="group"
            :can-edit="false"
            @refetch-data="getData(group.id)"
          />

        </b-col>
        <b-col
          cols="12"
          lg="6"
        >
          <b-card
            no-body
            class="card-employee-task"
          >
            <b-card-header>
              <b-card-title>{{$t('Dreamers')}}</b-card-title>
            </b-card-header>

            <!-- body -->
            <b-card-body>
              <template v-for="dreamer in group.dreamers">
              <div
                :key="dreamer.id"
                class="employee-task d-flex justify-content-between align-items-center"
              >
                <b-media no-body>
                  <b-media-aside class="mr-75">
                    <b-avatar
                      size="42"
                      :src="require('@/assets/images/avatar/AvatarSprite_'+(dreamer.avatar||'57')+'.png?v4')"
                    />
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h6 class="mb-0">
                      <b-link 
                        :to="{ name: 'school-dreamers-view', params: { 
                          id: school.id,
                          dreamer_id: dreamer.id 
                        } }"
                      >
                        {{ dreamer.name }}
                      </b-link>
                    </h6>
                    <small class="text-muted ">{{ dreamer.age }} {{$t('fields.years_old')}}</small>
                  </b-media-body>
                </b-media>
                
              </div>
              </template>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </template>
    <template v-else>
      <b-alert
        variant="danger"
        :show="group === undefined"
      >
        <h4 class="alert-heading">
          Error fetching group data
        </h4>
        <div class="alert-body">
          No group found with this id. Go back to
          <b-link
            class="alert-link"
            :to="{ name: 'schools'}"
          >
            Schools List
          </b-link>
        </div>
      </b-alert>
    </template>
    
  </div>
</template>

<script>
import router from '@/router'
import CustomBreadcrumb from '@/views/components/breadcrumb/CustomBreadcrumb.vue'
import InfoCard from './view/InfoCard.vue'
import {
  BRow, BCol, BButton,
    BCard, BCardTitle, BCardHeader, BCardBody, BMedia, 
    BMediaAside, BAvatar, BMediaBody, BAlert, BLink,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'

export default {
  components:{
    BRow, BCol, BAlert, BLink,BButton,
    BCard, BCardTitle, BCardHeader, BCardBody, BMedia, BMediaAside, BAvatar, BMediaBody,


    CustomBreadcrumb,
    InfoCard
  },
  setup(){
      return {
          avatarText
      }
  },
  data(){
    return {
      school: {},
      group: null,
      school_id:router.currentRoute.params.id
    }
  },
  mounted(){
    let id = router.currentRoute.params.group_id;
    let school_id = router.currentRoute.params.id;
    this.getData(id, school_id)
  },
  computed:{
    breadcrumb(){
       if(this.$store.getters['user/isSchool']){
          return [
              {
                text: this.$t('Schools'),
              },
              {
                text: this.school.name
              },
              {
                to: 'school-groups-list',
                text: this.$t('Groups'),
              },
              {
                text: this.$t('Detail'),
                active: true,
              },
            ]
          } else {

            return [
              {
                text: this.$t('Schools'),
                to: 'schools'
              },
              {
                to: 'school-view',
                params: {
                  id: this.school.id,
                },
                text: this.school.name
              },
              {
                to: 'school-groups-list',
                text: this.$t('Groups'),
              },
              {
                text: this.$t('Detail'),
                active: true,
              },
            ]
          }
    }
  },
  methods:{
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
    async getData(group_id, school_id){
        console.log("loading")
        let data = {
          'id': group_id,
          'school_id': school_id
        }
        await this.$http.post('school/fetchGroup', data).then( response =>{
            console.log(response)
            if(response.data.status==200){

             
                this.school = response.data.school;
                this.group = response.data.group;
            } else{
              this.makeToast('danger', this.$t('Error'), response.data.msg)
            }
        }).catch(error => {
            console.log("Err -> ", error)
        })
    },
  }
}
</script>

<style>

</style>